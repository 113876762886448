// eslint-disable-next-line import/prefer-default-export
export const configColumn = [
  {
    key: 'checkbox',
    label: '',
    thClass: 'pr-0 align-middle',
    tdClass: 'pr-0',
  },
  {
    key: 'no',
    label: 'No',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'title',
    label: 'Judul Campaign',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'target',
    label: 'Target Pengguna',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      textAlign: 'center',
    },
    tdClass: 'justify-center flex',
    class: 'p-1',
  },
  {
    key: 'status',
    label: 'Status',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-center',
    class: 'p-1 text-center',
  },
  {
    key: 'action',
    label: 'Aksi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-start min-w-[110px]',
    class: 'p-1',
    formatter: value => (value === 1 ? 'Aktif' : 'Non Aktif'),
  },
]

export const platform = [
  {
    id: 1,
    name: 'komerce',
    onCheck: false,
    img_url: 'https://storage.googleapis.com/komerce/core/brand.png',
  },
  {
    id: 2,
    name: 'affiliate',
    onCheck: false,
    img_url: 'https://storage.googleapis.com/komerce/assets/Affiliate/Komerce%20Affiliate.png',
  },
]
