<template>
  <BCard>
    <div class="text-[#626262] text-3xl font-semibold mb-2">
      Campaign - Blast Email
    </div>
    <BRow class="flex flex-row justify-between items-center gap-[10px] mb-1">
      <BCol md="6">
        <div class="flex items-center gap-[10px]">
          <BButton
            variant="outline-secondary"
            style="width: 40px;"
            class="d-flex justify-content-center align-items-center"
            :disabled="selected.length < 1"
            @click="deleteSelected()"
          >
            <BIcon
              icon="trash"
              aria-hidden="true"
            /></BButton>
          <b-badge
            v-if="selected.length >= 1"
            style="height: 20px;"
            class="badge-count"
            pill
            variant="danger"
          >
            {{ selected.length }}
          </b-badge>
          <div class="text-center">

            <button
              class="btn-outline-primary rounded-lg mb-[-3px]"
              @click="filter = !filter"
            >
              <img
                class="w-11 p-[5px]"
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/filter-search.svg"
                alt="Komerce"
              >
            </button>
            <ul
              v-if="filter"
              class="d-block position-absolute bg-[#f9f9f9] z-10 text-black font-medium text-[12px]"
            >
              <div class="text-black text-start pl-2 py-1 text-[16px] border">
                Filter
              </div>

              <div>
                <button
                  id="popoverBlast"
                  variant="outline-primary"
                  class="btn btn1 rounded-none mb-1 d-flex"
                >
                  <span>Status</span>
                </button>
                <BPopover
                  target="popoverBlast"
                  triggers="focus"
                  placement="left"
                >
                  <BFormCheckboxGroup v-model="selectedStatusBlast">
                    <div
                      v-for="(item, index) in statusBlast"
                      :key="index"
                    >
                      <BFormCheckbox
                        :id="'filter_' + index"
                        :value="item.value"
                        class="mt-1"
                      >
                        <div class="text-black">
                          {{ item.text }}
                        </div>

                      </BFormCheckbox>
                    </div>
                  </BFormCheckboxGroup>
                </BPopover>
              </div>
              <div class="hover:bg-[#FCD4BE] text-primary hover:text-white">
                <button
                  class="btn btn2 font-semibold mb-1 d-flex"
                  @click="handleResetFilter"
                >
                  <span class="btn-txt ">Reset Filter</span>
                </button>
              </div>
            </ul>
          </div>
          <div class="p-0 d-flex rounded-lg align-items-center border">
            <span class="k-search-normal-1 font-bold  align-middle mx-[5px] text-[20px]" />
            <BFormInput
              v-model="search"
              size="lg"
              placeholder="Cari Judul Campaign"
              class="border-0 w-100"
              maxlength="30"
              @input="searchData"
            />
          </div>
        </div>
      </BCol>
      <BCol
        md="3"
        class="text-end"
      >
        <div class="ml-1" />
        <BButton
          variant="primary"
          class="rounded-lg"
          to="/notification/create-blast"
        >
          <b-icon-plus /> Buat
        </BButton>
      </BCol>
    </BRow>
    <BOverlay
      variant="light"
      :show="isLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        :fields="fields"
        :items="items"
        responsive
        tbody-tr-class="hover:bg-slate-100 transition-colors duration-300 cursor-pointer"
        @row-clicked="(item, _, e) => handleAction(item)"
      >
        <template #head(checkbox)>
          <div class="d-flex justify-content-center">
            <BCheckbox
              v-model="allSelected"
              :indeterminate="indeterminate"
              @change="selectAll"
            />
          </div>
        </template>
        <template #cell(checkbox)="data">
          <div class="d-flex justify-content-center">
            <BCheckbox
              v-model="selected"
              :value="data.item"
              @change="select()"
            />
          </div>
        </template>
        <template #cell(no)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(title)="data">
          <div>{{ data.item.title }}</div>
          <div class="text-[#828282] flex gap-5 items-center">
            <div>{{ DDMMMMYYYY(data.item.created_at) }}</div>
          </div>
        </template>
        <template #cell(target)="data">
          <div
            v-for="(service, idx) in data.item.service_type"
            :key="idx"
            class="flex flex-wrap"
          >
            <BButton
              variant="outline-secondary"
              class="products d-flex align-items-center justify-content-center mr-[5px]"
              style="width: 40px; height: 40px"
            >
              <img
                :src="imageService(service)"
                alt="Komerce"
                style="max-width: 20px;"
              >
            </BButton>
          </div>
        </template>
        <template #cell(status)="data">
          <span
            :class="classStatus(data.item.status)"
            class="font-medium"
          >
            {{ labelStatus(data.item.status) }}
          </span>
        </template>
        <template #cell(updated_at)="data">
          <div>{{ formatDate(data.item.updated_at) }}</div>
          <span class="text-muted">{{ formatTime(data.item.updated_at) }}</span>
        </template>
        <template #cell(action)="data">
          <span
            class="text-[#08A0F7] cursor-pointer underline"
            @click="handleAction(data.item)"
          >
            Detail
          </span>
        </template>
      </BTable>
    </BOverlay>
  </BCard>
</template>
<script>
import moment from 'moment'
import { newAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import { DDMMMMYYYY } from '@/libs/filterDate'
import { configColumn } from './config'

export default {
  data() {
    return {
      fields: configColumn,
      items: [],
      selected: [],
      allSelected: false,
      isLoading: false,
      status: 0,
      active: 0,
      alertError,
      alertSuccess,
      DDMMMMYYYY,
      search: '',
      filter: false,
      selectedStatusBlast: [],
      statusBlast: [
        { text: 'Draft', value: 2 },
        { text: 'Selesai', value: 3 },
      ],
      indeterminate: false,
      is_reload: true,
    }
  },
  watch: {
    selectedStatusNotif: 'getList',
    selectedStatusBlast: 'getList',

    selected(newValue, oldValue) {
      if (newValue.length === 0) {
        this.indeterminate = false
      } else if (newValue.length === this.items.length) {
        this.indeterminate = false
      } else {
        this.indeterminate = true
      }
    },
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      this.isLoading = true
      const params = {
        search: this.search,
        status_email: this.selectedStatusBlast.join(','),
        is_quick_reload: this.is_reload,
      }

      const url = '/sender/api/v1/campaign/blast-email'

      try {
        const res = await newAxiosIns.get(url, { params })
        const { data } = res.data
        this.items = data
      } catch (error) {
        this.alertError('Gagal load data campaign')
      } finally {
        this.isLoading = false
      }
    },
    searchData: _.debounce(function search() {
      this.getList()
    }, 1000),
    formatDate(date) {
      const validDate = moment(date)
      if (validDate.isValid()) {
        const monthName = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']
        const day = moment(date).format('DD')
        const month = moment(date).format('M')
        const year = moment(date).format('YYYY')
        return `${day} ${monthName[month - 1]} ${year}`
      }
      return ''
    },
    formatTime(date) {
      const validDate = moment(date)
      if (validDate.isValid()) {
        const time = moment(date).format('HH:mm')
        return time
      }
      return ''
    },
    selectAll(checked) {
      // eslint-disable-next-line no-plusplus
      this.selected = checked ? this.items.slice() : []
    },
    select() {
      this.allSelected = false
    },
    deleteSelected() {
      this.isLoading = true
      this.$swal({
        title: '<span class="font-medium text-[16px]">Kamu yakin ingin menghapus blast email ini?</span>',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.svg'),
        confirmButtonText: 'Hapus',
        confirmButtonClass: 'btn btn-primary rounded-lg',
        showCancelButton: true,
        cancelButtonText: 'Batal',
        cancelButtonClass: 'btn btn-outline-primary bg-white text-primary rounded-lg',
      }).then(res => {
        const { isConfirmed } = res
        if (isConfirmed) {
          const url = '/sender/api/v1/campaign/action-campaign'
          newAxiosIns.post(url, {
            action: 'delete',
            data:
                this.selected.map(item => ({
                  id: item.id,
                  type_campaign: item.type_campaign,
                })),
          })
            .then(() => {
              this.isLoading = false
              this.alertSuccess(`Berhasil hapus ${this.selected.length} data Blast Email`)
              this.getList()
              this.selected = []
            })
            .catch(() => {
              this.isLoading = false
              this.alertError('Gagal hapus data')
            })
        } else {
          this.isLoading = false
          this.$swal.close()
        }
      })
    },
    click() {
      this.status = this.status === 1 ? 0 : 1
    },
    campaignType(type) {
      if (type === 1) {
        return 'Notification Banner'
      }
      return 'Blast Email'
    },
    imageCampaignType(type) {
      if (type === 1) {
        return 'https://storage.googleapis.com/komerce/assets/illustration/notification-yellow.svg'
      }
      return 'https://storage.googleapis.com/komerce/assets/illustration/sms-blue.svg'
    },
    classCampaignType(type) {
      if (type === 1) {
        return 'text-[#FBBC05]'
      }
      return 'text-[#4285F4]'
    },
    imageService(service) {
      if (service === 'komship' || service.startsWith('komship_')) {
        return 'https://storage.googleapis.com/komerce/assets/LP-Komerce/komship.svg'
      } if (service === 'affiliate') {
        return 'https://storage.googleapis.com/komerce/assets/logo/logo-only/Logo-affiliate.svg'
      } if (service === 'komplace' || service.startsWith('komplace_')) {
        return 'https://storage.googleapis.com/komerce/assets/LP-Komerce/komplace.svg'
      } if (service === 'komcards' || service.startsWith('komcards_')) {
        return 'https://storage.googleapis.com/komerce/assets/LP-Komerce/komcards.svg'
      } if (service === 'komtim' || service.startsWith('komtim_')) {
        return 'https://storage.googleapis.com/komerce/assets/LP-Komerce/komtim.svg'
      } if (service === 'testing') {
        return 'https://storage.googleapis.com/komerce/assets/icons/icon-dev-black.svg'
      } if (service === 'raja_ongkir' || service.startsWith('raja_ongkir_')) {
        return 'https://storage.googleapis.com/komerce/assets/LP-Rajaongkir/apple-touch-icon.png'
      }
      return 'https://storage.googleapis.com/komerce/assets/logo/logo-only/Logo-komerce.svg'
    },
    labelStatus(status) {
      if (status === 0) {
        return 'Tidak Aktif'
      }
      if (status === 1) {
        return 'Aktif'
      }
      if (status === 2) {
        return 'Draft'
      }
      return 'Selesai'
    },
    classStatus(status) {
      if (status === 0) {
        return 'text-[#E31A1A]'
      }
      if (status === 1) {
        return 'text-[#34A770]'
      }
      if (status === 2) {
        return 'text-[#828282]'
      }
      return 'text-[#34A770]'
    },
    handleAction({ id, type_campaign, status }) {
      // eslint-disable-next-line camelcase
      if (type_campaign === 1) {
        return this.$router.push(`/notification/detail/notif/${id}`)
      }
      if (status === 2) {
        return this.$router.push(`/notification/create-blast/${id}`)
      }
      return this.$router.push(`/notification/detail/blast/${id}`)
    },
    async handleResetFilter() {
      this.selectedStatusBlast = []
      this.selectedStatusNotif = []
    },
  },
}
</script>
<style lang="scss" scoped>
@import './notification';

.btn1:hover {
  background-color: #F95031;
  color: white;
  border-radius: 0;
}

.btn-txt {
  color: #F95031;
  font-weight: 600;
}
.badge-count {
    position: absolute;
    margin-left: 30px;
    margin-top: -10px;
}
.products {
  border-radius: 20px;
  border-color: #C2C2C2 !important;
}

</style>
